/* eslint-disable prettier/prettier */
import axios from 'axios'
import Config from 'src/configurations/Config';

const ApiClient = axios.create({
  // baseURL: 'https://localhost:7117',
  baseURL: Config.ApiClient.BaseUrl,
  headers: {
    'Content-Type': 'application/json-patch+json',
  }
})

var jwt = "Bearer " + JSON.parse(localStorage.getItem('token'))?.token;
ApiClient.defaults.headers.common['Authorization'] = jwt;

ApiClient.interceptors.request.use(function (cfg) {
  // Do something before request is sent
  // console.log(cfg);

  return cfg;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

function logOut() {
  var url = '/login?error=' + ApiClient.customMessage;
  url = url + '&returnUrl=' + window.location.pathname;
  localStorage.removeItem('token')
  window.location.replace(url);
  // if
  //   (
  //   window.location.href.includes("/login")
  //   ||
  //   window.location.href.includes("/register")
  // )
  return;
}

//todo: 'ApiClient.customMessage' is not the best practice
ApiClient.customMessage = '';
ApiClient.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  ApiClient.customMessage = '';
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  console.log(error)

  if (!error?.response) {
    ApiClient.customMessage = "Sunucu Yanıt Vermiyor !"
    // logOut();
    return;
  }

  if (error.response?.status === 401) {
    ApiClient.customMessage = "Oturum Sonlandı. Lütfen Tekrar Giriş Yapın";
    logOut();
    return;
  }


  if (error.response?.data !== undefined || error.response?.data !== '') {
    //eğer validasyon hatası varsa
    var validationErrors = error.response.data.errors;
    if (validationErrors) {
      var errMsg = "";
      for (var key in validationErrors) {
        var value = validationErrors[key];
        for (var i = 0; i < value.length; i++) {
          const element = value[i];
          errMsg += "•" + element + "\n";
        }
      }
      ApiClient.customMessage = errMsg;
      return;

    }

    // eğer varsa apiden zaten anlamlı response message dönüyor
    ApiClient.customMessage = error.response?.data;


  }
  //eğer server bir hata status code ile yanıt vermiş ve anlamlı bir hata mesajı döndürmemişse buradan biz ilgili status code'a göre hata mesajımızı set ediyoruz
  else if (error.response.status === 404) {
    ApiClient.customMessage = "Not Found";
  } else if (error.response.status === 400) {
    ApiClient.customMessage = "???";
  }


  // alert(ApiClient.customMessage);
  // return Promise.reject(error);
});

export default ApiClient;
